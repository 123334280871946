










































import { NavigationGuardNext, Route } from 'vue-router';
import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import PasswordInputExtended from '@/components/PasswordInputExtended.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import { getDefaultRouteByRole } from '@/router';
import { token } from '@/helpers/authHelpers';
import { parseInviteToken } from '@/helpers/tokenHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { isEmail, required } from '@/helpers/validation';
import { isGameProviderAdmin } from '@/helpers/gameProviderAdminHelper';
import {
  registerUserFromInvite,
  registerGameProviderFromInvite
} from '@/api/Auth';

import { http } from '@/api/Connect';
import SecureLS from 'secure-ls';
import { IAuthGameProviderInviteTokenData } from '@/api/schema';

const removeToken = (): void => {
  const ls = new SecureLS();
  ls.remove('tokenKey');
  http.removeToken();
};

export default {
  name: 'InviteVerifyView',

  components: {
    AuthLayout,
    BaseLoader,
    PasswordInputExtended
  },

  data(): any {
    return {
      formValid: true,
      email: '',
      password: '',
      isSubmit: false,
      isLoading: true,
      isEmailDisabled: false,
      emailValidationRules: [(v) => required(v, 'E-mail'), isEmail]
    };
  },

  computed: {
    headerContent(): string {
      return this.isEmailDisabled
        ? 'Please create a password to log into your account'
        : 'Please create login and password to log into your account';
    },
    hasData(): boolean {
      return [this.email, this.password].every((el) => el.length);
    }
  },

  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ): Promise<void> {
    if (!token) {
      next('/login');

      return;
    }
    removeToken();
    next();
  },

  async mounted(): Promise<void> {
    try {
      const { email, email_confirmation } = this.getInviteTokenData(token);

      if (email) {
        this.email = email;
        this.isEmailDisabled = true;
      }

      if (email_confirmation) {
        this.verify = true;
        const authToken = await this.registerUser();
        await this.loginUser(authToken);
        this.redirectUserAfterLogin();
      }
    } catch (e) {
      errorToastMessage(e);
      const query = this.$route.query;
      delete query.token;
      await this.$router.replace({ path: '/login', query });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit(): Promise<void> {
      try {
        this.isSubmit = true;
        const isFormValid = await this.$refs.form.validate();
        if (isFormValid) {
          const authToken = await this.registerUser();
          await this.loginUser(authToken);
          this.redirectUserAfterLogin();
        }
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.isSubmit = false;
      }
    },
    async registerUser(): Promise<string> {
      if (isGameProviderAdmin) {
        const { token: authToken } = await registerGameProviderFromInvite({
          password: this.password,
          token
        });
        return authToken;
      } else {
        const { token: authToken } = await registerUserFromInvite({
          email: this.email,
          password: this.password,
          token
        });
        return authToken;
      }
    },
    async loginUser(token: string): Promise<void> {
      await this.$store.dispatch('Auth/authorize', token);
    },
    redirectUserAfterLogin(): void {
      const toastMessage = this.verify
        ? 'Email confirmed.'
        : 'Login complete.';
      const query = this.$route.query;
      delete query.token;
      this.$router.replace(
        { path: getDefaultRouteByRole(this.$role.name), query },
        () => this.$toast.success(toastMessage)
      );
    },
    getInviteTokenData(token: string): {
      email: string;
      email_confirmation: boolean;
    } {
      let email: string,
        email_confirmation = false;

      if (isGameProviderAdmin) {
        const parseTokenData =
          parseInviteToken<IAuthGameProviderInviteTokenData>(token);
        email = JSON.parse(parseTokenData.create_provider_request)?.email;
      } else {
        const parseTokenData = parseInviteToken(token);
        email = parseTokenData.email;
        email_confirmation = parseTokenData.email_confirmation;
      }

      return { email, email_confirmation };
    }
  }
};
